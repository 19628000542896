import React from 'react';

const Modal = ({ message, type, onClose }) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black">
            <div className={`bg-${type === 'success' ? 'green-600' : 'red-600'} p-6 rounded shadow-lg`}>
                <h2 className="text-white text-lg mb-4">{type === 'success' ? 'Success' : 'Error'}</h2>
                <p className="text-white mb-4">{message}</p>
                <button
                    className="bg-white text-black font-semibold py-2 px-4 rounded"
                    onClick={onClose}
                >
                    Close
                </button>
            </div>
        </div>
    );
};

export default Modal;