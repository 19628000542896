import React from 'react';
import { Link } from 'react-router-dom';
import './allgames.css'; // Ensure to use the correct path for your CSS

const AllGame = () => {
  return (
    <div className="all-games-container">
      <h1 className="title">All Games</h1>
      <h2 className="game-title">Developer @itstarzan</h2>
      
      <div className="games-list">
        {/* Link to StarCatcher */}
        <div className="game-item">
          <h2 className="game-title">Star Catcher</h2>
          <Link to="/gamehome">
            <button className="play-button">Play Star Catcher</button>
          </Link>
        </div>
        
        {/* Link to BoatGame */}
        <div className="game-item">
          <h2 className="game-title">Hold Star Game</h2>
          <Link to="/boatgame">
            <button className="play-button">Play Hold Star</button>
          </Link>
        </div>

       
        
        {/* Link to Game (Your other game) */}
        <div className="game-item">
          <h2 className="game-title">Daily Combo</h2>
          <Link to="/game">
            <button className="play-button">Play Now</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AllGame;
