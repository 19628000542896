import React, { useEffect, useState } from 'react';
import { db } from '../firebase/firestore'; // Adjust the import based on your file structure
import { doc, getDoc, updateDoc, Timestamp, setDoc } from 'firebase/firestore';
import { useUser } from '../context/userContext';
import { FaCalendarAlt } from 'react-icons/fa';
import Animate from '../Components/Animate';
import './DailyCheckIn.css';

const DailyCheckIn = () => {
  const {
    id,
    setError,
    error,
    checkInDays,
    setCheckInDays,
    balance,
    setBalance,
  } = useUser();

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [claiming, setClaiming] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [selectedBoxes, setSelectedBoxes] = useState(Array(4).fill(null));
  const [claimEnabled, setClaimEnabled] = useState(false);
  const [correctSelections, setCorrectSelections] = useState([]);

  // Include all 8 image filenames
  const images = ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg', '7.jpg', '8.jpg'];
  const bonusPoints = 5000; // Reward points for the user

  useEffect(() => {
    // Fetch correct images on component mount
    const fetchCorrectImages = async () => {
      const dailyComboDocRef = doc(db, 'DailyCombo', 'C9HVN5q79lOlf0WXXaDE');
      const dailyComboDoc = await getDoc(dailyComboDocRef);
      if (dailyComboDoc.exists()) {
        const comboData = dailyComboDoc.data();
        setCorrectSelections(comboData.correctImages || []); // Ensure it defaults to an empty array
      }
    };

    fetchCorrectImages();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (id) {
        updateTimeRemaining();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [id, checkInDays]);

  const updateTimeRemaining = async () => {
    const userComboDocRef = doc(db, 'DailyComboUsers', id);
    const userComboDoc = await getDoc(userComboDocRef);
    if (userComboDoc.exists()) {
      const userData = userComboDoc.data();
      const now = new Date();
      const lastCheckInDate = userData.lastCheckIn?.toDate();

      if (lastCheckInDate) {
        const lastCheckInMidnight = new Date(lastCheckInDate);
        lastCheckInMidnight.setHours(0, 0, 0, 0);
        const nextClaimTime = new Date(lastCheckInMidnight.getTime() + 24 * 60 * 60 * 1000);
        const timeDiff = nextClaimTime - now;

        if (timeDiff > 0) {
          const hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);
          const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
          const seconds = Math.floor((timeDiff / 1000) % 60);
          setTimeRemaining(`${hours}h ${minutes}m ${seconds}s`);
        } else {
          setTimeRemaining(null); // Allow claiming
        }
      }
    }
  };

  const handleSelectImage = (image) => {
    const index = selectedBoxes.findIndex((box) => box === null);
    if (index !== -1) {
      const updatedBoxes = [...selectedBoxes];
      updatedBoxes[index] = image;
      setSelectedBoxes(updatedBoxes);
      checkIfClaimable(updatedBoxes);
    } else {
      setError('All boxes are filled! Reset or complete.');
    }
  };

  const checkIfClaimable = (boxes) => {
    const isClaimable = correctSelections.every((img, i) => img === boxes[i]);
    setClaimEnabled(isClaimable);
  };

  const handleReset = () => {
    setSelectedBoxes(Array(4).fill(null));
    setClaimEnabled(false);
    setError(null);
  };

  const handleDailyCheckIn = async () => {
    if (!id) return;

    if (!claimEnabled) {
      setError('Complete the boxes with the correct images to claim!');
      return;
    }

    setClaiming(true);
    setError(null);

    try {
      const userComboDocRef = doc(db, 'DailyComboUsers', id);
      const userComboDoc = await getDoc(userComboDocRef);
      const now = new Date();

      // Create the DailyComboUsers document if it doesn't exist
      if (!userComboDoc.exists()) {
        await setDoc(userComboDocRef, {
          lastCheckIn: Timestamp.fromDate(now),
          balance: bonusPoints,
        });
      } else {
        const userData = userComboDoc.data();
        const lastCheckInDate = userData.lastCheckIn?.toDate();

        if (lastCheckInDate) {
          const timeDiff = now - lastCheckInDate;
          if (timeDiff < 24 * 60 * 60 * 1000) {
            setError('You have already claimed your Combo. Please come back after time ends.');
            setClaiming(false);
            return;
          }
        }
        
        // Update Firestore with new balance and check-in information
        await updateDoc(userComboDocRef, {
          lastCheckIn: Timestamp.fromDate(now),
          balance: (userData.balance || 0) + bonusPoints,
        });
      }

      setShowSuccessModal(true);  // Show congrats modal
      setBalance(balance + bonusPoints);  // Update state with new points
      handleReset();  // Reset the boxes

    } catch (err) {
      console.error('Error during Daily Check-In:', err);
      setError('Failed to claim the reward. Please try again.');
    } finally {
      setClaiming(false);
    }
  };

  const renderBoxes = () => {
    return selectedBoxes.map((box, index) => (
      <div key={index} className="box">
        {box ? (
          <img src={box} alt={`Selected ${index}`} className="w-full h-full object-cover rounded-md" />
        ) : (
          <span className="placeholder">?</span>
        )}
      </div>
    ));
  };

  const renderImageOptions = () => {
    return (
      <div className="image-options">
        {images.map((image, index) => (
          <button key={index} onClick={() => handleSelectImage(image)}>
            <img src={image} alt={`Image ${index + 1}`} className="image-option" />
          </button>
        ))}
      </div>
    );
  };

  return (
    <>
      <Animate>
        <div className="daily-checkin-container">
          <h1 className="title">Daily Combo</h1>
          <p className="caption">Fulfill all 4 boxes with correct images to claim daily combo. After selecting the correct images, the claim button will be shown.</p>

          <div className="time-remaining">
            <FaCalendarAlt className="icon" />
            <span>{timeRemaining ? `Next Combo: ${timeRemaining}` : 'Claim Now!'}</span>
          </div>

          <div className="box-container">
            {renderBoxes()} {/* This renders the 4 boxes */}
          </div>

          {error && (
            <div className="error-message">
              {error}
            </div>
          )}

          {claimEnabled && (
            <button onClick={handleDailyCheckIn} disabled={claiming} className="claim-button">
              {claiming ? 'Claiming...' : 'Claim Reward'}
            </button>
          )}

          <button onClick={handleReset} className="reset-button">
            Reset
          </button>

          <div className="image-selection">
            <h2 className="subtitle">Select Images</h2>
            {renderImageOptions()}
          </div>
        </div>
      </Animate>

      {showSuccessModal && (
        <div className="success-modal">
          <div className="w-full flex justify-center flex-col items-center space-y-3">
            <p className="font-medium text-center">Congratulations!</p>
            <span className="font-medium text-[20px] text-[#ffffff] pt-2 pb-2 flex flex-col justify-center w-full text-center items-center space-x-1">
              <span className='flex items-center justify-center space-x-[2px] text-[18px]'>
                <img src='/dogs.webp' alt='sdfd' className='w-[15px]' />
              </span>
            </span>
          </div>
          <div className="fixed z-50 flex items-center justify-center left-0 right-0 top-0 bottom-0 bg-[#303030c4]">
            <div className="bg-cards p-4 rounded-lg text-center">
              <p>You have successfully claimed 5000 $Stars.</p>
              <button
                className="mt-4 bg-accent rounded-lg py-2 px-4 text-white" onClick={() => setShowSuccessModal(false)}>OK</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DailyCheckIn;
