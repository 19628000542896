import React, { useEffect, useState } from 'react';
import { db } from '../firebase/firestore'; // Ensure your Firebase Firestore is correctly set up
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { useUser } from '../context/userContext'; // Custom hook for user context
import { useNavigate } from 'react-router-dom';
import Modal from './Modal'; // Ensure you have a Modal component for messages
import { useAdsgram } from '../hooks/useAdsgram'; // Custom hook for ads management

// Initialize Adsgram globally
window.Adsgram.init({ blockId: "4038" });

const GameHome = () => {
    const { id, firstName } = useUser();
    const [dogPoints, setDogPoints] = useState(0);
    const [starPoints, setStarPoints] = useState(0);
    const [withdrawDogAmount, setWithdrawDogAmount] = useState(0);
    const [withdrawStarAmount, setWithdrawStarAmount] = useState(0);
    const [withdrawType, setWithdrawType] = useState('both');
    const [playCount, setPlayCount] = useState(0);
    const [lastPlayDate, setLastPlayDate] = useState(null); // Track last play date
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState(''); // 'success' or 'error'
    const [showModal, setShowModal] = useState(false);
    const [adError, setAdError] = useState('');
    const navigate = useNavigate();

    // Define ad behavior: reward and error handling
    const handleReward = async () => {
        // Increment play count after successfully watching an ad
        const newPlayCount = playCount + 1; // Increment play count
        await updatePlayCount(newPlayCount); // Update Firestore
        navigate('/starcatcher'); // Redirect to Star Catcher
    };

    const handleError = (error) => {
        console.error('Ad error:', error);
        setAdError('Ad is not available at the moment. Please try again later.');
    };

    // Set up Adsgram
    const showAd = useAdsgram({ 
        blockId: '4038',
        onReward: handleReward,
        onError: handleError,
    });

    // Watch Ad Click Handler
    const handleWatchAdClick = () => {
        console.log('Trying to show ad...');
        showAd(); // Show ad when clicked
    };

    useEffect(() => {
        // Fetch user data from Firestore
        const fetchUserData = async () => {
            if (!id) return;

            try {
                const stardogsDocRef = doc(db, 'stardogs', id);
                const stardogsDoc = await getDoc(stardogsDocRef);

                if (stardogsDoc.exists()) {
                    setDogPoints(stardogsDoc.data().dogPoints || 0);
                    setStarPoints(stardogsDoc.data().starPoints || 0);
                    const userPlayCount = stardogsDoc.data().playCount || 0;
                    const lastDate = stardogsDoc.data().lastPlayDate || null;

                    // Check if the last play date is today
                    const today = new Date().toISOString().split('T')[0]; // Get current date

                    if (lastDate !== today) {
                        // Reset play count if a new day
                        await setDoc(stardogsDocRef, { playCount: 0, lastPlayDate: today }, { merge: true });
                        setPlayCount(0);
                    } else {
                        setPlayCount(userPlayCount); // Set the play count from Firestore
                    }
                }
            } catch (err) {
                console.error('Error fetching user data:', err);
            }
        };

        fetchUserData();
    }, [id]);

    const handlePlayNow = async () => {
        if (playCount < 10) {
            const newPlayCount = playCount + 1;
            await updatePlayCount(newPlayCount); // Increment play count when starting a game
            navigate('/starcatcher'); // Start game directly
        } else {
            handleWatchAdClick(); // Show ad for extra play
        }
    };

    const updatePlayCount = async (newCount) => {
        await setDoc(doc(db, 'stardogs', id), { playCount: newCount }, { merge: true });
        setPlayCount(newCount);
    };

    const handleWithdrawDogChange = (e) => {
        setWithdrawDogAmount(e.target.value);
    };

    const handleWithdrawStarChange = (e) => {
        setWithdrawStarAmount(e.target.value);
    };

    const handleWithdrawTypeChange = (e) => {
        setWithdrawType(e.target.value);
        setWithdrawDogAmount(0);
        setWithdrawStarAmount(0);
    };

    const handleWithdraw = async () => {
        const dogAmount = withdrawType === 'dogs' ? parseFloat(withdrawDogAmount) : Math.max(0, parseFloat(withdrawDogAmount));
        const starAmount = withdrawType === 'stars' ? parseFloat(withdrawStarAmount) : Math.max(0, parseFloat(withdrawStarAmount));
        let withdrawalData = [];

        // Validation for dogs withdrawal
        if (withdrawType === 'dogs' && dogAmount > 0) {
            if (dogAmount < 5000) {
                setMessage("Minimum withdrawal amount for dogs is 5000.");
                setMessageType("error");
                setShowModal(true);
                return;
            }
            if (dogAmount > dogPoints) {
                setMessage("Insufficient dog points.");
                setMessageType("error");
                setShowModal(true);
                return;
            }
            withdrawalData.push({
                type: 'dogs',
                amount: dogAmount,
                createdAt: new Date(),
            });
        }

        // Validation for stars withdrawal
        if (withdrawType === 'stars' && starAmount > 0) {
            if (starAmount < 5000) {
                setMessage("Minimum withdrawal amount for stars is 5000.");
                setMessageType("error");
                setShowModal(true);
                return;
            }
            if (starAmount > starPoints) {
                setMessage("Insufficient star points.");
                setMessageType("error");
                setShowModal(true);
                return;
            }
            withdrawalData.push({
                type: 'stars',
                amount: starAmount,
                createdAt: new Date(),
            });
        }

        // Handle both types
        if (withdrawType === 'both') {
            if (dogAmount > 0) {
                withdrawalData.push({
                    type: 'dogs',
                    amount: dogAmount,
                    createdAt: new Date(),
                });
            }
            if (starAmount > 0) {
                withdrawalData.push({
                    type: 'stars',
                    amount: starAmount,
                    createdAt: new Date(),
                });
            }
        }

        // If no valid withdrawal data is present
        if (withdrawalData.length === 0) {
            setMessage("Please enter a valid withdrawal amount.");
            setMessageType("error");
            setShowModal(true);
            return;
        }

        // Processing the withdrawal
        try {
            const withdrawalDocRef = doc(db, 'withdrawals', id);
            const existingDoc = await getDoc(withdrawalDocRef);
            let existingWithdrawals = [];

            if (existingDoc.exists()) {
                existingWithdrawals = existingDoc.data().withdrawals || [];
            }

            const combinedWithdrawals = [...existingWithdrawals, ...withdrawalData];
            await setDoc(withdrawalDocRef, { withdrawals: combinedWithdrawals }, { merge: true });

            // Deducting points accordingly
            for (const withdrawal of withdrawalData) {
                if (withdrawal.type === 'dogs') {
                    setDogPoints(prev => prev - withdrawal.amount);
                    await setDoc(doc(db, 'stardogs', id), { dogPoints: dogPoints - withdrawal.amount }, { merge: true });
                } else {
                    setStarPoints(prev => prev - withdrawal.amount);
                    await setDoc(doc(db, 'stardogs', id), { starPoints: starPoints - withdrawal.amount }, { merge: true });
                }
            }

            setMessage("Withdrawal(s) successful!");
            setMessageType("success");
            setShowModal(true);
            setWithdrawDogAmount(0);
            setWithdrawStarAmount(0);
        } catch (error) {
            console.error("Error processing withdrawal:", error);
            setMessage("Withdrawal failed. Please try again.");
            setMessageType("error");
            setShowModal(true);
        }
    };

    return (
        <div id="refer" className="w-full items-center justify-center h-screen pt-5 scroller overflow-y-auto pb-[150px] space-y-3">
            <div className="w-full max-w-md space-y-4 overflow-y-auto pb-[240px] items-center text-center">
                <h1 className="text-4xl mb-4">Star Dogs Catcher</h1>
                <h2 className="text-lg mb-2">Welcome, {firstName ? firstName : "Player"}!</h2>

                {showModal && (
                    <Modal
                        message={message}
                        type={messageType}
                        onClose={() => setShowModal(false)}
                    />
                )}

                {playCount < 10 ? (
                    <button 
                        className="mt-4 bg-green-600 hover:bg-green-500 text-white font-semibold py-2 px-4 rounded" 
                        onClick={handlePlayNow}
                    >
                        Play Now
                    </button>
                ) : ( 
                    <button 
                        className="mt-4 bg-blue-600 hover:bg-blue-500 text-white font-semibold py-2 px-4 rounded" 
                        onClick={handleWatchAdClick}
                    >
                        Watch Ad for Extra Play
                    </button>
                )}

                <div className="bg-gray-900 bg-opacity-75 p-4 rounded-lg mb-4">
                    <p>Stars: {starPoints.toFixed(2)}</p>
                    <p>Dogs: {dogPoints.toFixed(2)}</p>
                    <p>Play Count: {playCount} / 10</p>
                </div>

                {/* Error message display for ads */}
                {adError && <p className="text-red-500 mt-2">{adError}</p>}

                <div className="bg-gray-800 bg-opacity-75 p-4 rounded-lg mb-4">
                    <h3 className="text-xl mb-2">Withdraw</h3>
                    <div className="mb-4">
                        <label className="block mb-2" htmlFor="withdrawType">Withdraw Type:
                            <select id="withdrawType" value={withdrawType} onChange={handleWithdrawTypeChange} className="mt-1 block w-full bg-gray-900 text-white border border-gray-600 rounded px-3 py-2">
                                <option value="both">Both Dogs and Stars</option>
                                <option value="dogs">Dogs</option>
                                <option value="stars">Stars</option>
                            </select>
                        </label>
                    </div>

                    {(withdrawType === 'dogs' || withdrawType === 'both') && (
                        <div className="mb-4">
                            <label className="block mb-1">
                                Withdraw Dogs Amount:
                                <input 
                                    type="number" 
                                    value={withdrawDogAmount} 
                                    onChange={handleWithdrawDogChange} 
                                    min="0" 
                                    className="mt-1 block w-full bg-gray-900 text-white border border-gray-600 rounded px-3 py-2" 
                                />
                            </label>
                        </div>
                    )}

                    {(withdrawType === 'stars' || withdrawType === 'both') && (
                        <div className="mb-4">
                            <label className="block mb-1">
                                Withdraw Stars Amount:
                                <input 
                                    type="number" 
                                    value={withdrawStarAmount} 
                                    onChange={handleWithdrawStarChange} 
                                    min="0" 
                                    className="mt-1 block w-full bg-gray-900 text-white border border-gray-600 rounded px-3 py-2" 
                                />
                            </label>
                        </div>
                    )}

                    <button className="bg-blue-600 hover:bg-blue-500 text-white font-semibold py-2 px-4 rounded" onClick={handleWithdraw}>
                        Withdraw
                    </button>
                </div>
            </div>
        </div>
    );
};

export default GameHome;