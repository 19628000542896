import React, { useEffect, useRef, useState } from 'react';
import { useIsConnectionRestored, useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import { IoCheckmarkCircle, IoCloseCircle } from "react-icons/io5";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { TonConnectButton } from "@tonconnect/ui-react";
import { doc, updateDoc } from 'firebase/firestore'; // Import Firestore functions
import { db } from '../firebase/firestore';
import { useUser } from '../context/userContext';



export const Address = () => {
  const { id, isAddressSaved, setWalletAddress, setIsAddressSaved } = useUser();
  const userFriendlyAddress = useTonAddress();
  const [disconnect, setDisconnect] = useState(false);
  const [openInfoTwo, setOpenInfoTwo] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const infoRefTwo = useRef(null);
  const infoRef = useRef(null);

  const handleClickOutside = (event) => {
    if (infoRefTwo.current && !infoRefTwo.current.contains(event.target)) {
      setOpenInfoTwo(false);
    }
    if (infoRef.current && !infoRef.current.contains(event.target)) {
      setOpenInfo(false);
    }
  };

  useEffect(() => {
    if (openInfoTwo || openInfo) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openInfoTwo, openInfo]);

  const clearCache = () => {
    localStorage.clear();
    sessionStorage.clear();
    if ('caches' in window) {
      caches.keys().then(names => {
        names.forEach(name => {
          caches.delete(name);
        });
      });
    }
  };

  const [tonConnectUI] = useTonConnectUI();
  const connectionRestored = useIsConnectionRestored();

  useEffect(() => {
    if (!connectionRestored) {
      setTimeout(() => {
        clearCache();
      }, 8000);
    }
  }, [connectionRestored]);

  // Function to handle disconnection
  const handleDisconnect = async () => {
    const userRef = doc(db, 'telegramUsers', id.toString());
    try {
      await updateDoc(userRef, {
        address: '',
        isAddressSaved: false,
      });
      setIsAddressSaved(false);
      setWalletAddress('');
      setDisconnect(!disconnect);
      setOpenInfo(false);
      console.log('Address disconnected successfully');
    } catch (error) {
      console.error('Error deleting address:', error);
    }
    disconn();
  };

  const disconn = async () => {
    try {
      await tonConnectUI.disconnect();
      console.log('Disconnected successfully');
    } catch (error) {
      console.error('Error during disconnection:', error);
    }
  };

  const saveAddressToFirestore = async () => {
    const userRef = doc(db, 'telegramUsers', id.toString());
    try {
      await updateDoc(userRef, {
        address: userFriendlyAddress,
        isAddressSaved: true,
      });
      setIsAddressSaved(true);
      setWalletAddress(userFriendlyAddress);
      console.log('Address saved successfully');
    } catch (error) {
      console.error('Error saving address:', error);
    }
  };

  const handleConnect = async () => {
    if (userFriendlyAddress && userFriendlyAddress.startsWith('0Q')) {
      setShowErrorModal(true);
      await tonConnectUI.disconnect(); // Disconnect testnet address immediately
      return false;
    } else {
      saveAddressToFirestore();
      return true;
    }
  };

  useEffect(() => {
    if (!isAddressSaved && userFriendlyAddress) {
      handleConnect();
    }
  }, [userFriendlyAddress]);

  return (
    <>
      <div className="w-full rounded-[15px] flex flex-col justify-center items-center relative">
        {userFriendlyAddress !== '' ? (
          <button
            onClick={() => setOpenInfo(true)}
            className={`bg-[#a4a4a424] flex h-full w-[70%] rounded-full items-center py-[10px] px-3 relative space-x-1`}
          >
            <img src='/wallet.webp' alt="connect" className="w-[16px] -mt-[2px]" />
            <div className="text-[13px] small-text2 text-left pr-3 text-nowrap text-white flex flex-1 flex-col">
              <h4 className='font-semibold text-[#d1d1d1] line-clamp-1 break-all text-wrap'>
                {userFriendlyAddress}
              </h4>
            </div>
            <IoCheckmarkCircle size={20} className='text-[#40863d]' />
          </button>
        ) : (
          <button
            onClick={() => setOpenInfoTwo(true)}
            className={`bg-[#319cdf] flex h-full w-[70%] rounded-full items-center justify-center py-[13px] px-4 relative space-x-1`}
          >
            <img src='/wallet.webp' alt="connect" className="w-[16px] -mt-[1px]" />
            <div className="text-[13px] small-text2 font-medium text-left text-nowrap text-white flex flex-col">
              Connect your wallet
            </div>
            <MdOutlineKeyboardArrowRight size={20} className='text-[#fff] mb-[-1px]' />
          </button>
        )}
      </div>

      {/* Error Modal for Testnet Address */}
      {showErrorModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-[80%] sm:w-[40%] text-center">
            <h3 className="text-lg font-semibold text-red-600">Invalid Address</h3>
            <p className="mt-4 text-sm text-gray-700">
              Testnet addresses are not allowed. Please connect a Ton mainnet address.
            </p>
            <button
              className="mt-6 bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600 transition duration-300"
              onClick={() => setShowErrorModal(false)}
            >
              Okay
            </button>
          </div>
        </div>
      )}

      {/* Connect wallet modal */}
      <div
        className={`${
          openInfoTwo === true ? "visible" : "invisible"
        } fixed bottom-0 left-0 z-40 right-0 h-[100vh] bg-[#00000042] flex justify-center items-center backdrop-blur-[6px] px-4`}
      >
        <div ref={infoRefTwo} className={`${
          openInfoTwo === true ? "opacity-100 mt-0 ease-in duration-300" : "opacity-0 mt-[100px]"
        } w-full bg-modal absolute bottom-0 left-0 right-0 rounded-tr-[40px] rounded-tl-[40px] flex flex-col justify-center p-8`}>
          <div className="w-fit flex justify-center absolute right-6 top-6">
            <button
              onClick={() => setOpenInfoTwo(false)}
              className="w-fit flex items-center justify-center text-center rounded-[12px] font-medium text-[16px]"
            >
              <IoCloseCircle size={32} className="text-[#8f8f8f]" />
            </button>
          </div>

          <div className="w-full flex justify-center flex-col items-center space-y-3 pt-6 pb-32">
            <div className="w-full items-center justify-center flex flex-col space-y-2">
              <span className="w-[72px] flex items-center">
                <img src='/wallet.webp' alt="connect" className="w-full" />
              </span>
              <h3 className='font-semibold text-[22px] w-full text-center'>Connect your TON wallet</h3>
              <p className="pb-6 text-[#bfbfbf] text-[14px] w-full text-center">
                Connect your crypto wallet to receive airdrop allocation. If you don't have one, create one in your Telegram account.
              </p>
              <div className='w-full flex flex-col items-center justify-center space-y-4'>
                <TonConnectButton className="!w-full" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Disconnect wallet modal */}
      <div
        className={`${
          openInfo === true ? "visible" : "invisible"
        } fixed bottom-0 left-0 z-40 right-0 h-[100vh] bg-[#00000042] flex justify-center items-center backdrop-blur-[6px] px-4`}
      >
        <div ref={infoRef} className={`${
          openInfo === true ? "opacity-100 mt-0 ease-in duration-300" : "opacity-0 mt-[100px]"
        } w-full bg-modal absolute bottom-0 left-0 right-0 rounded-tr-[40px] rounded-tl-[40px] flex flex-col justify-center p-8`}>
          <div className="w-fit flex justify-center absolute right-6 top-6">
            <button
              onClick={() => setOpenInfo(false)}
              className="w-fit flex items-center justify-center text-center rounded-[12px] font-medium text-[16px]"
            >
              <IoCloseCircle size={32} className="text-[#8f8f8f]" />
            </button>
          </div>

          <div className="w-full flex justify-center flex-col items-center space-y-3 pt-6 pb-32">
            <div className="w-full items-center justify-center flex flex-col space-y-2">
              <span className="w-[72px] flex items-center">
                <img src='/wallet.webp' alt="disconnect" className="w-full" />
              </span>
              <h3 className='font-semibold text-[22px] w-full text-center'>Disconnect Wallet</h3>
              <p className="pb-6 text-[#bfbfbf] text-[14px] w-full text-center">
                Are you sure you want to disconnect your wallet?
              </p>
              <div className='w-full flex flex-col items-center justify-center space-y-4'>
                <button
                  onClick={handleDisconnect}
                  className='bg-[#ff4d4d] hover:bg-[#ff3333] text-white font-medium rounded-[10px] px-6 py-3'
                >
                  Disconnect Wallet
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
